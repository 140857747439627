import { GlobalWizardState, LOBState } from "stores/wizard/state";
import { observable, action, computed, makeObservable } from "mobx";
import { LxConfig } from "stores/wizard/config";
import { TypeaheadSelection } from "uitk-react-typeahead";
import { WizardValidationState } from "../validations/WizardValidationState";
import { ValidationData, ValidationState } from "../validations/typings";

export class LxWizardState implements LOBState {
  public get location() {
    return this.globalState.location;
  }

  private globalState: GlobalWizardState;

  public get isDesktop() {
    return this.globalState.isDesktop;
  }

  public config: LxConfig;
  public subLOBState = "";
  public moduleName = "wizard-lx-pwa";
  public trackingEnabled = true;
  public validations: ValidationState;
  public destinationInvalidKey: string | "";
  public dateStartInvalidKey = "";
  public dateEndInvalidKey = "";
  public wizardInputsArray: React.RefObject<HTMLInputElement>[] = [];
  public errorInputRef: React.RefObject<HTMLHeadingElement> | null;
  public errorSummaryRef: React.RefObject<HTMLInputElement> | null;
  public numberOfErrors = 0;

  //Since this LOB doesn't have a subLOB, setting this as empty so the queryParam is removed when navigating to this LOB
  public updateSubLOBState(subLOBState: string) {
    this.subLOBState = "";
  }

  moreThanOneError = () => {
    return this.globalState.moreThanOneError(this.numberOfErrors);
  };

  public get date() {
    return this.globalState.date;
  }

  public setDefaultDestination = (
    destinationValue: string,
    regionID: string,
    countryCode: string,
    ttla: string,
    destinationID: string,
    hotelID: string
  ) => {
    this.globalState.updateDestinationFromContext(
      destinationValue,
      regionID,
      countryCode,
      "",
      ttla,
      destinationID,
      hotelID
    );
    this.destinationInvalidKey = "";
  };

  public overrideConfig(callback: () => void): void {
    callback();
  }

  public updateDateSelection = (start: Date, end: Date) => {
    this.globalState.updateDateSelection(start, end);
    this.dateStartInvalidKey = "";
    this.dateEndInvalidKey = "";
  };

  public updateDestinationSelection = (selection: TypeaheadSelection) => {
    this.globalState.updateDestinationSelection(selection);
  };

  public updateDateFromConfig = () => {
    this.globalState.updateDateFromConfig(this.config.date);
  };

  /**
   * Validations
   */

  public resetValidations = () => {
    this.destinationInvalidKey = "";
    this.dateStartInvalidKey = "";
    this.dateEndInvalidKey = "";
  };

  public cleanErrorState = () => {
    this.errorInputRef = null;
    this.numberOfErrors = 0;
  };

  public updateStateValidations = (validationsData: any) => {
    this.updateLocErrorKeys(validationsData.invalidKeys);
    this.errorInputRef = validationsData.inputReference;
    this.numberOfErrors = validationsData.numberOfErrors;
  };

  public updateLocErrorKeys = (invalidKeys: any) => {
    this.dateEndInvalidKey = invalidKeys.dateEnd;
    this.destinationInvalidKey = invalidKeys.destination;
  };

  public submit = (event: React.FormEvent) => {
    const dataToValidate: ValidationData = {
      location: {
        origin: null,
        destination: this.location.destination.value,
      },
      dates: {
        start: this.date.start,
        end: this.date.end,
      },
      references: this.wizardInputsArray,
    };

    const { isValid, data } = this.validations.validateForm(dataToValidate, this.config);

    if (!isValid) {
      this.updateStateValidations(data);
      event.preventDefault();
      this.validations.focusErrorSummary();
    }
  };

  constructor(config: LxConfig, globalState: GlobalWizardState) {
    makeObservable(this, {
      config: observable,
      subLOBState: observable,
      trackingEnabled: observable,
      destinationInvalidKey: observable,
      dateStartInvalidKey: observable,
      dateEndInvalidKey: observable,
      errorInputRef: observable,
      errorSummaryRef: observable,
      numberOfErrors: observable,
      location: computed,
      date: computed,
      updateSubLOBState: action,
      moreThanOneError: action,
      overrideConfig: action,
      updateDateSelection: action,
      updateDestinationSelection: action,
      resetValidations: action,
      cleanErrorState: action,
    });

    this.config = config;
    this.globalState = globalState;
    this.validations = new WizardValidationState();
  }
}
