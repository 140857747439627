import { GlobalWizardState, LOBState } from "stores/wizard/state";
import { observable, action, computed, makeObservable } from "mobx";
import { CruiseConfig } from "stores/wizard/config";
import { TypeaheadSelection } from "uitk-react-typeahead";
import { Travelers } from "src/components/shared/TravelersField/typings";
import { travelersMetadata } from "src/components/shared/TravelersField/utils";
import { travelersStateOnConfig } from "../global/travelers/travelersState";
import { TravelersState } from "stores/wizard/state/global";
import { ValidationData, ValidationState } from "../validations/typings";
import { WizardValidationState } from "../validations/WizardValidationState";

export class CruiseWizardState implements LOBState {
  private globalState: GlobalWizardState;

  public get canTrack() {
    return this.globalState.canTrack;
  }

  public get location() {
    return this.globalState.location;
  }

  public get travelersValueChanged() {
    return this.globalState.travelersValueChanged;
  }

  public get isDesktop() {
    return this.globalState.isDesktop;
  }

  public setTravelersValue = () => {
    this.globalState.setTravelersValue();
  };

  public config: CruiseConfig;
  public subLOBState: string;
  public destination3pp = "";
  public cruiseLine3pp = "";
  public duration3pp = "";
  public departurePort3pp = "";
  public dateRange3pp = "";
  public siteId = 0;
  public numberOfErrors = 0;
  public errorInputRef: React.RefObject<HTMLHeadingElement> | null;
  public wizardInputsArray: React.RefObject<HTMLInputElement>[] = [];

  //Since this LOB doesn't have a subLOB, setting this as empty so the queryParam is removed when navigating to this LOB
  public updateSubLOBState() {
    return false;
  }

  public get date() {
    return this.globalState.date;
  }

  public overrideConfig(callback: () => void): void {
    callback();
  }

  public updateDateRange3pp(value: string) {
    this.dateRange3pp = value;
  }

  public updateDestination3pp(value: string) {
    this.destination3pp = value;
  }

  public updateDuration3pp(value: string) {
    this.duration3pp = value;
  }

  public updateDeparturePort3pp(value: string) {
    this.departurePort3pp = value;
  }

  public updateCruiseLine3pp(value: string) {
    this.cruiseLine3pp = value;
  }

  public cruiseTravelers: TravelersState;

  public get travelers() {
    return this.cruiseTravelers;
  }

  public get hotelTravelersMetadata() {
    return travelersMetadata(this.travelers.hotel);
  }

  public get nonHotelTravelersMetadata() {
    return travelersMetadata(this.travelers.nonHotel);
  }

  public get cruiseTravelersMetadata() {
    return travelersMetadata(this.travelers.cruise);
  }

  public updateNonHotelTravelersSelection = (travelers: Travelers) => {
    this.globalState.updateNonHotelTravelersSelection(travelers, this.travelers);
  };

  public cruiseDestination = "";

  public setCruiseDestination = (destination: string) => {
    this.cruiseDestination = destination;
    this.destinationInvalidKey = "";
  };

  public dateStartInvalidKey = "";
  public dateEndInvalidKey = "";
  public destinationInvalidKey: string | "";
  public nonHotelTravelersInvalidKey = "";

  public validations: ValidationState;

  moreThanOneError = () => {
    return this.globalState.moreThanOneError(this.numberOfErrors);
  };

  public validateLessThanNTravelers = () => {
    if (!this.globalState.validateLessThanNTravelers(this.nonHotelTravelersMetadata, this.config.travelers)) {
      this.nonHotelTravelersInvalidKey = this.config.travelers.invalidLessThanNTravelersMessageToken;

      return false;
    }

    return true;
  };

  public updateDateSelection = (start: Date, end: Date) => {
    this.globalState.updateDateSelection(start, end);
  };

  public updateDestinationSelection = (selection: TypeaheadSelection) => {
    this.globalState.updateDestinationSelection(selection);
  };

  public resetValidations = () => {
    this.nonHotelTravelersInvalidKey = "";
    this.destinationInvalidKey = "";
    this.numberOfErrors = 0;

    return true;
  };

  public validateChildrenFields = () => {
    const childrenWithoutAge = this.globalState.validateChildrenFields(this.travelers);

    if (!this.config.travelers.withRooms && Boolean(childrenWithoutAge)) {
      if (childrenWithoutAge === 1) {
        this.nonHotelTravelersInvalidKey = this.config.travelers.invalidChildValueMessageToken!;
      } else {
        this.nonHotelTravelersInvalidKey = this.config.travelers.invalidChildrenValuesMessageToken!;
      }

      return false;
    }

    return true;
  };

  public validateInfantFields = () => {
    const infantWithoutAge = this.globalState.validateInfantFields(this.travelers);

    if (!this.config.travelers.withRooms && Boolean(infantWithoutAge)) {
      if (infantWithoutAge === 1) {
        this.nonHotelTravelersInvalidKey = this.config.travelers.invalidInfantValueMessageToken!;
      } else {
        this.nonHotelTravelersInvalidKey = this.config.travelers.invalidInfantsValuesMessageToken!;
      }

      return false;
    }

    return true;
  };

  public validateDestinationField = () => {
    const { start } = this.date;
    const dataToValidate: ValidationData = {
      location: {
        origin: null,
        destination: this.cruiseDestination,
      },
      dates: {
        start,
        end: null,
      },
      references: this.wizardInputsArray,
    };
    const { isValid, data } = this.validations.validateForm(dataToValidate, this.config);

    if (!isValid) {
      this.updateStateValidations(data);
      this.validations.focusErrorSummary();
      return false;
    }

    return true;
  };

  private updateStateValidations = (validationsData: any) => {
    this.errorInputRef = validationsData.inputReference;
    this.numberOfErrors = validationsData.numberOfErrors;
    this.updateLocErrorKeys(validationsData.invalidKeys);
  };

  private updateLocErrorKeys = (invalidKeys: any) => {
    this.destinationInvalidKey = invalidKeys.destination;
  };

  public cleanErrorState = () => {
    if (this.errorInputRef) {
      this.errorInputRef = null;
    }
    this.numberOfErrors = 0;
  };

  public validateTravelersField = () =>
    this.validateLessThanNTravelers() && this.validateChildrenFields() && this.validateInfantFields();

  public validateForm = () => {
    const validations: boolean[] = [this.validateTravelersField(), this.validateDestinationField()];

    return !validations.includes(false);
  };

  public submit = (event: React.FormEvent) => {
    const isValidForm = this.validateForm();
    if (!isValidForm) {
      event.preventDefault();
    }
    this.globalState.submit();
  };

  public populateDefaultDateIfRequired = () => {
    const cruisersSitesDefDateReq = [70125];

    if (cruisersSitesDefDateReq.includes(this.siteId) && this.dateRange3pp === "") {
      this.dateRange3pp = "-1";
    }
  };

  public submit3pp = () => {
    this.populateDefaultDateIfRequired();

    this.globalState.submit();
  };

  public updateDateFromConfig = () => {
    this.globalState.updateDateFromConfig(this.config.date);
  };

  constructor(config: CruiseConfig, globalState: GlobalWizardState) {
    makeObservable(this, {
      config: observable,
      subLOBState: observable,
      destination3pp: observable,
      cruiseLine3pp: observable,
      duration3pp: observable,
      departurePort3pp: observable,
      dateRange3pp: observable,
      siteId: observable,
      numberOfErrors: observable,
      errorInputRef: observable,
      cruiseTravelers: observable,
      cruiseDestination: observable,
      dateStartInvalidKey: observable,
      dateEndInvalidKey: observable,
      destinationInvalidKey: observable,
      nonHotelTravelersInvalidKey: observable,
      canTrack: computed,
      location: computed,
      travelersValueChanged: computed,
      date: computed,
      travelers: computed,
      hotelTravelersMetadata: computed,
      nonHotelTravelersMetadata: computed,
      cruiseTravelersMetadata: computed,
      setTravelersValue: action,
      updateSubLOBState: action,
      overrideConfig: action,
      updateDateRange3pp: action,
      updateDestination3pp: action,
      updateDuration3pp: action,
      updateDeparturePort3pp: action,
      updateCruiseLine3pp: action,
      updateNonHotelTravelersSelection: action,
      setCruiseDestination: action,
      moreThanOneError: action,
      validateLessThanNTravelers: action,
      updateDateSelection: action,
      updateDestinationSelection: action,
      resetValidations: action,
      validateChildrenFields: action,
      validateInfantFields: action,
      cleanErrorState: action,
      validateTravelersField: action,
      populateDefaultDateIfRequired: action,
    });

    this.config = config;
    this.cruiseTravelers = travelersStateOnConfig(this.config.travelers);
    this.globalState = globalState;
    this.validations = new WizardValidationState();
  }
}
