import { TravelersState } from "stores/wizard/state/global/travelers";
import { InfantSeating } from "src/components/shared/TravelersField/InfantSeating";
import { TravelersFieldConfig } from "src/stores/wizard/config";

export const travelersStateOnConfig = (config: TravelersFieldConfig): TravelersState => {
  return {
    hotel: {
      rooms: [
        {
          adults: config.defaultTravelers ?? 1,
          children: [],
          infants: [],
        },
      ],
      infantSeating: InfantSeating.ON_LAP,
    },
    hotelPackage: {
      rooms: [
        {
          adults: config.defaultTravelers ?? 1,
          children: [],
          infants: [],
        },
      ],
      infantSeating: InfantSeating.ON_LAP,
    },
    nonHotel: {
      rooms: [
        {
          adults: config.defaultTravelers ?? 1,
          children: [],
          infants: [],
        },
      ],
      infantSeating: InfantSeating.ON_LAP,
    },
    cruise: {
      rooms: [
        {
          adults: config.defaultTravelers ?? 1,
          children: [],
          infants: [],
        },
      ],
      infantSeating: InfantSeating.ON_LAP,
    },
  };
};

export const travelersState: TravelersState = {
  hotel: {
    rooms: [
      {
        adults: 2,
        children: [],
        infants: [],
      },
    ],
    infantSeating: InfantSeating.ON_LAP,
  },
  hotelPackage: {
    rooms: [
      {
        adults: 2,
        children: [],
        infants: [],
      },
    ],
    infantSeating: InfantSeating.ON_LAP,
  },
  nonHotel: {
    rooms: [
      {
        adults: 2,
        children: [],
        infants: [],
      },
    ],
    infantSeating: InfantSeating.ON_LAP,
  },
  cruise: {
    rooms: [
      {
        adults: 2,
        children: [],
        infants: [],
      },
    ],
    infantSeating: InfantSeating.ON_LAP,
  },
};
