import { observable, action, makeObservable } from "mobx";
import { ConfigLOB, ValidationData } from "./typings";
import { isToday, isSelectedHourValid, isValidTimeDuration, removeTime } from "./utils";
import { Maybe } from "__generated__/typedefs";

export class WizardValidationState {
  /**
   * General validation
   */

  public validationsData: ValidationData;

  public config: ConfigLOB;

  public errorInputRef: React.RefObject<HTMLHeadingElement> | null;

  public errorSummaryRef: React.RefObject<HTMLInputElement> | null;

  /**
   * Finds reference in array of references that are part of the wizard.
   * @param keyword - part of inner html to be found in array of references
   * @returns element reference
   */
  findFocusElement = (keyword: string) => {
    if (this.validationsData.references) {
      return this.validationsData.references.find(
        (element: any) => element.current !== null && element.current.outerHTML.includes(keyword)
      );
    }
    return null;
  };

  // Location validations

  /**
   * Validates if destination field is empty
   * @returns if is valid returns empty string if not returns lockey error
   */
  public validateDestinationField = () => {
    const { destination, origin } = this.validationsData.location;
    const { location } = this.config;

    if (location.destination) {
      const { inputName, invalidLocationMessageKey, invalidOriginSameAsDestinationKey } = location.destination;

      const isNotValidDestination = destination ? "" : invalidLocationMessageKey;
      const isSameOriginAndDestination =
        invalidOriginSameAsDestinationKey && origin === destination ? invalidOriginSameAsDestinationKey : "";

      if (isNotValidDestination || isSameOriginAndDestination) {
        this.updateErrorInputRef(inputName);

        return isNotValidDestination || isSameOriginAndDestination || "";
      }
    }
    return "";
  };

  public validateOriginField = () => {
    const { origin } = this.validationsData.location;
    const { location } = this.config;

    if (location.origin) {
      const { inputName, invalidLocationMessageKey } = location.origin;
      const defaultInputName = "origin";

      if (!origin) {
        this.updateErrorInputRef(inputName ?? defaultInputName);
        return invalidLocationMessageKey;
      }
    }

    return "";
  };

  //Dates validations

  /**
   * Validates date end field
   * @returns if is valid returns empty string if not returns lockey error
   */
  public validateEndDate = (): string => {
    const { dates } = this.validationsData;
    const { maxDaysRange } = this.config.date;
    const validateExistingEndDateMsg = this.validateExistingEndDate(dates.end);
    const validateMaxDateRange = dates.end ? this.validateMaxDateRange(dates.start, dates.end, maxDaysRange) : "";
    const validateEndDateBeforeStartDate = dates.end ? this.validateEndDateBeforeStartDate(dates.start, dates.end) : "";

    return validateExistingEndDateMsg || validateMaxDateRange || validateEndDateBeforeStartDate || "";
  };

  public validateExistingEndDate = (end?: Maybe<Date>) => {
    const { invalidRequiredEndDateMessageToken } = this.config.date;
    if (!end) {
      this.updateErrorInputRef("d2");

      return invalidRequiredEndDateMessageToken;
    }

    return "";
  };

  public validateMaxDateRange = (startDate: Date, endDate: Date, maxDaysRange: number): string => {
    const { invalidMaxDatesRangeMessageToken } = this.config.date;
    const maxEndDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + maxDaysRange + 1);

    if (endDate > maxEndDate) {
      this.updateErrorInputRef("d2");

      return invalidMaxDatesRangeMessageToken;
    }

    return "";
  };

  public validateEndDateBeforeStartDate = (start: Date, end: Date) => {
    const { invalidEndDateMessageToken } = this.config.date;
    const startDate = removeTime(start);
    const endDate = removeTime(end);

    if (endDate < startDate) {
      this.updateErrorInputRef("d2");

      return invalidEndDateMessageToken;
    }

    return "";
  };

  //Time validations
  public validateStartTime = () => {
    const { time, dates } = this.validationsData;

    const isValid = time?.pickup ? !isToday(dates.start) && !isSelectedHourValid(time.pickup) : true;

    if (!isValid) {
      this.updateErrorInputRef("d2");
      return this.config.date.invalidStartTimeMessageToken;
    }

    return "";
  };

  public validateEndTime = () => {
    const { time, dates } = this.validationsData;
    const inputName = "Flight departure time";
    const isValid =
      time?.pickup && time.dropoff && dates.end
        ? isValidTimeDuration(dates.start, dates.end, time?.pickup, time?.dropoff)
        : true;

    if (!isValid) {
      this.updateErrorInputRef(inputName);
      return this.config.date.invalidEndTimeMessageToken;
    }

    return "";
  };

  //Other inputs/components
  public validateDriversAgeField = () => {
    const invalidAgeKey = "wizard.car.driverAge.input.invalidText";
    if (!this.validationsData.inputs?.cars.driversAge) {
      this.updateErrorInputRef("age");

      return invalidAgeKey;
    }

    return "";
  };
  //Add hotel checkbox dates

  public validateAddHotelCheckboxEndDate = () => {
    const { addHotelCheckbox, dates } = this.validationsData;
    if (this.config.hotelDate) {
      const { maxDaysRange } = this.config.hotelDate;

      if (addHotelCheckbox?.dates.end && dates.end) {
        const validateMaxDateRange = this.validateAddHotelCheckboxMaxDateRange(
          addHotelCheckbox.dates.start,
          addHotelCheckbox.dates.end,
          maxDaysRange
        );

        const validatePartialDateWithinRange = this.validatePartialDateWithinRange(
          dates.start,
          dates.end,
          addHotelCheckbox.dates.end
        );

        if (validateMaxDateRange || validatePartialDateWithinRange) {
          this.updateErrorInputRef("d2");
        }

        return validateMaxDateRange || validatePartialDateWithinRange || "";
      }
    }

    return "";
  };

  public validateAddHotelCheckboxMaxDateRange = (startDate: Date, endDate: Date, maxDaysRange: number): string => {
    const maxEndDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + maxDaysRange + 1);

    const invalidToken = this.config.hotelDate?.invalidMaxDatesRangeMessageToken ?? "";

    if (endDate > maxEndDate) {
      return invalidToken;
    }

    return "";
  };

  public validateAddHotelCheckboxStartDate = () => {
    const { addHotelCheckbox, dates } = this.validationsData;

    if (addHotelCheckbox) {
      const validatePartialDateWithinRange =
        dates.end && this.validatePartialDateWithinRange(dates.start, dates.end, addHotelCheckbox.dates.start);
      const validatePartialStartDate = this.validatePartialStartDate(addHotelCheckbox.dates.start, dates.start);

      if (validatePartialDateWithinRange || validatePartialStartDate) {
        this.updateErrorInputRef("d1");
      }

      return validatePartialDateWithinRange || validatePartialStartDate || "";
    }
    return "";
  };

  public validatePartialStartDate = (partialStartDate: Date, startDate: Date) => {
    const oneWayInvalidHotelStartToken = this.config.hotelDate?.invalidOnewayHotelStartDateMessageToken ?? "";

    if (partialStartDate < startDate) {
      return oneWayInvalidHotelStartToken;
    }

    return "";
  };

  public validatePartialDateWithinRange = (rangeStart: Date, rangeEnd: Date, date: Date): string => {
    const minValidDate = new Date(rangeStart.getFullYear(), rangeStart.getMonth(), rangeStart.getDate());
    const maxValidDate = new Date(rangeEnd.getFullYear(), rangeEnd.getMonth(), rangeEnd.getDate(), 23, 59, 59, 999);

    if (date >= minValidDate && date <= maxValidDate) {
      return "";
    }

    const invalidKey = this.config.hotelDate?.invalidEndDateOutOfRangeMessageToken ?? "";

    return invalidKey;
  };

  // References for A11y

  public focusErrorSummary = () => {
    this.errorSummaryRef = this.findFocusElement("error") ?? null;
    this.errorSummaryRef?.current?.focus();
  };

  updateErrorInputRef = (keyword: string) => {
    if (!this.errorInputRef) {
      this.errorInputRef = this.findFocusElement(keyword) ?? null;
    }
  };

  getValidations = (validationsData: ValidationData) => {
    const { location, time, inputs, addHotelCheckbox, dates } = validationsData;

    const validateOrigin = location.origin !== null;
    const validateDestination = location.destination !== null;
    const validateEndDate = dates.end !== null;
    const validateStartTime = time && time.pickup !== null;
    const validateEndTime = time && time.dropoff !== null;
    const validateDriversAge = inputs && inputs !== null && inputs?.cars !== null;
    const validateAddHotelCheckboxStartDate = addHotelCheckbox && addHotelCheckbox !== null;
    const validateAddHotelCheckboxEndDate = addHotelCheckbox && addHotelCheckbox !== null;

    const validations = {
      origin: validateOrigin ? this.validateOriginField() : "",
      destination: validateDestination ? this.validateDestinationField() : "",
      dateEnd: validateEndDate ? this.validateEndDate() : "",
      startTime: validateStartTime ? this.validateStartTime() : "",
      endTime: validateEndTime ? this.validateEndTime() : "",
      driversAge: validateDriversAge ? this.validateDriversAgeField() : "",
      addHotelCheckboxStartDate: validateAddHotelCheckboxStartDate ? this.validateAddHotelCheckboxStartDate() : "",
      addHotelCheckboxEndDate: validateAddHotelCheckboxEndDate ? this.validateAddHotelCheckboxEndDate() : "",
    };

    return validations;
  };

  public validateForm = (validationsData: ValidationData, lobConfig: ConfigLOB) => {
    this.validationsData = validationsData;
    this.errorInputRef = null;
    this.config = lobConfig;

    const validations = this.getValidations(validationsData);

    const errorsCount = Object.values(validations).filter((value) => value !== "").length;

    return {
      isValid: errorsCount === 0,
      data: {
        numberOfErrors: errorsCount,
        inputReference: this.errorInputRef,
        invalidKeys: {
          destination: validations.destination,
          dateEnd: validations.dateEnd,
          origin: validations.origin,
          startTime: validations.startTime,
          endTime: validations.endTime,
          driversAge: validations.driversAge,
          addHotelCheckboxStartDate: validations.addHotelCheckboxStartDate,
          addHotelCheckboxEndDate: validations.addHotelCheckboxEndDate,
        },
      },
    };
  };

  public constructor() {
    makeObservable(this, {
      validationsData: observable,
      config: observable,
      errorInputRef: observable,
      errorSummaryRef: observable,
      findFocusElement: action,
      validateDestinationField: action,
      validateOriginField: action,
      validateEndDate: action,
      validateMaxDateRange: action,
      validateEndDateBeforeStartDate: action,
      validateStartTime: action,
      validateEndTime: action,
      validateDriversAgeField: action,
      focusErrorSummary: action,
      updateErrorInputRef: action,
      getValidations: action,
      validateForm: action,
    });
  }
}
