/* istanbul ignore file */
import { observable, action, computed, makeObservable } from "mobx";
import { TypeaheadSelection } from "uitk-react-typeahead";
import { TypeaheadDataProps, TypeaheadSelectionProps } from "src/components/flexComponents/WizardHotelPWA/typings";
import { LocationState } from "../global/location/typings";
import { GtConfig } from "../../config/staticConfigs/car/typings";
import { LocationType } from "../typings";

export class GroundTransportationLocationState {
  public get destinationConfig() {
    return this.config.location.destination;
  }

  public get originConfig() {
    return this.config.location.origin;
  }

  public config: GtConfig;
  public location: LocationState;
  public isOriginAirport = true;
  public destinationInvalidKey = "";
  public originInvalidKey: string | "";
  public regionId = "";
  public hotelId = "";
  public airportCode = "";

  public resetValidations = () => {
    this.originInvalidKey = "";
    this.destinationInvalidKey = "";
  };

  public swapTypeaheadLocations() {
    this.destinationInvalidKey = "";
    this.originInvalidKey = "";
    this.swapTypeaheadConfigs();
    this.swapTypeaheadCurrentData();
    this.isOriginAirport = !this.isOriginAirport;
  }

  public swapTypeaheadConfigs() {
    const tempDestinationConfig = this.config.location.destination;
    this.config.location.destination = this.config.location.origin;
    this.config.location.origin = tempDestinationConfig;
  }

  public swapTypeaheadCurrentData() {
    const tempDestinationLocation = this.location.destination;
    this.location.destination = this.location.origin;
    this.location.origin = tempDestinationLocation;
  }

  public updateDestinationSelection(selection: TypeaheadSelection) {
    const { term, data } = selection as TypeaheadSelectionProps;
    this.location.destination.value = term;
    this.location.destination.metaData.regionId = data?.regionId || data?.cityId;
    this.location.destination.metaData.ttla = data?.hierarchyInfo?.airport?.airportCode;
    this.location.destination.metaData.countryCode = data?.hierarchyInfo?.country?.isoCode2;
    this.location.destination.metaData.shortName = data?.regionNames?.shortName;
    this.location.destination.metaData.latLong =
      data?.location?.lat && data?.location?.long ? `${data.location.lat},${data.location.long}` : "";
    this.handleLocationData(data);

    this.destinationInvalidKey = "";
  }

  public updateOriginSelection(selection: TypeaheadSelection) {
    const { term, data } = selection as TypeaheadSelectionProps;
    this.location.origin.value = term;
    this.location.origin.metaData.regionId = data && data.regionId;
    this.location.origin.metaData.ttla = data?.hierarchyInfo?.airport.airportCode;
    this.location.origin.metaData.countryCode = data?.hierarchyInfo?.country?.isoCode2;
    this.location.origin.metaData.shortName = data?.regionNames?.shortName;
    this.location.origin.metaData.latLong =
      data?.location?.lat && data?.location?.long ? `${data.location.lat},${data.location.long}` : "";
    this.handleLocationData(data);

    this.originInvalidKey = "";
  }

  public updateFieldsFromQueryParams = (queryParams: URLSearchParams) => {
    const hotelLocation = queryParams.get("location");
    const hotelName = queryParams.get("hotelName");
    const locationFromTypeKey = this.isOriginAirport ? "origin" : "destination";
    const locationToTypeKey = this.isOriginAirport ? "destination" : "origin";

    if (hotelLocation) {
      this.location[locationFromTypeKey].value = decodeURI(hotelLocation);
    }
    if (hotelName) {
      this.location[locationToTypeKey].value = decodeURI(hotelName);
    }
    this.regionId = queryParams.get("regionId") ?? "";
  };

  public validateOriginField = (): boolean => !!this.location.origin.value;

  public validateDestinationField = (): boolean => !!this.location.destination.value;

  private handleLocationData = (data: TypeaheadDataProps) => {
    if (data.type === LocationType.HOTEL) {
      this.hotelId = data?.selected;
      this.location.origin.metaData.hotelId = data?.selected;
      this.location.destination.metaData.hotelId = data?.selected;
    } else {
      this.regionId = data?.hierarchyInfo.airport.multicity;
      this.airportCode = data?.hierarchyInfo?.airport?.airportCode;
    }
  };

  constructor(config: GtConfig, location: LocationState) {
    makeObservable(this, {
      config: observable,
      location: observable,
      isOriginAirport: observable,
      destinationInvalidKey: observable,
      originInvalidKey: observable,
      regionId: observable,
      hotelId: observable,
      airportCode: observable,
      destinationConfig: computed,
      originConfig: computed,
      resetValidations: action,
      swapTypeaheadLocations: action,
      swapTypeaheadConfigs: action,
      swapTypeaheadCurrentData: action,
      updateDestinationSelection: action,
      updateOriginSelection: action,
    });

    this.config = config;
    this.location = location;
  }
}
