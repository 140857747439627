import { LOBState } from "stores/wizard/state";
import { ThirdPartyPackageConfig } from "stores/wizard/config";
import { safeResponseToJson } from "components/utility/FetchUtils";
import { observable, action, computed, makeObservable } from "mobx";
import { GlobalWizardState } from "../global";
import { Location3PPDestination, ThirdPPDuration } from "./typings";
import { travelersMetadata } from "src/components/shared/TravelersField/utils";
import { Room, Travelers } from "src/components/shared/TravelersField/typings";

export class ThirdPartyPackageWizardState implements LOBState {
  private globalState: GlobalWizardState;

  public get location() {
    return this.globalState.location;
  }

  public subLOBState = "";

  //Since this LOB doesn't have a subLOB, setting this as empty so the queryParam is removed when navigating to this LOB
  public updateSubLOBState(subLOBState: string) {
    return false;
  }

  public get travelersValueChanged() {
    return this.globalState.travelersValueChanged;
  }

  public setTravelersValue = () => {
    this.globalState.setTravelersValue();
  };

  //We have this method to achieve the LOBState implementation
  public updateDestinationSelection = (selection: any) => {
    this.globalState.updateDestinationSelection(selection);
    this.destinationInvalidKey = "";
  };

  public config: ThirdPartyPackageConfig;

  public siteid: number;

  public locale: string;

  public get canTrack() {
    return this.globalState.canTrack;
  }

  public offersWithTransfer = false;
  public destination = "";
  public origin = "";
  public duration = "";
  public destinations: any[] = [];
  public origins: any[] = [];
  public durations: any[] = [];
  public dateStartInvalidKey = "";
  public dateEndInvalidKey = "";
  public destinationInvalidKey = "";
  public originInvalidKey = "";
  public durationInvalidKey = "";
  public nonHotelTravelersInvalidKey = "";
  public formAction = "";
  public errorInputRef: React.RefObject<HTMLHeadingElement> | null;
  public numberOfErrors = 0;
  public wizardInputsArray: React.RefObject<HTMLInputElement>[] = [];

  public get date() {
    return this.globalState.date;
  }

  constructor(config: ThirdPartyPackageConfig, globalState: GlobalWizardState) {
    makeObservable(this, {
      subLOBState: observable,
      offersWithTransfer: observable,
      destination: observable,
      origin: observable,
      duration: observable,
      destinations: observable,
      origins: observable,
      durations: observable,
      dateStartInvalidKey: observable,
      dateEndInvalidKey: observable,
      destinationInvalidKey: observable,
      originInvalidKey: observable,
      durationInvalidKey: observable,
      nonHotelTravelersInvalidKey: observable,
      formAction: observable,
      errorInputRef: observable,
      numberOfErrors: observable,
      location: computed,
      travelersValueChanged: computed,
      canTrack: computed,
      date: computed,
      travelers: computed,
      nonHotelTravelersMetadata: computed,
      updateSubLOBState: action,
      setTravelersValue: action,
      updateDestinationSelection: action,
      toggleOffersWithTransfer: action,
      setOrigin: action,
      setDestination: action,
      setDuration: action,
      updateDurations: action,
      updateDestinations: action,
      updateFormAction: action,
      validateMaxDateRange: action,
      validateEndDateField: action,
      resetValidations: action,
      findFocusElement: action,
      validateOriginField: action,
      validateDurationField: action,
      validateDestinationField: action,
      validateChildrenFields: action,
      validateInfantFields: action,
      validateTravelersField: action,
      validateLessThanNTravelers: action,
      overrideConfig: action,
      updateDateSelection: action,
      updateNonHotelTravelersSelection: action,
      moreThanOneError: action,
    });
    this.config = config;
    this.origins = config.origins || [];
    this.destinations = config.destinations || [];
    this.durations = config.durations || [];
    this.globalState = globalState;
  }

  private selectFirstOrigin = () => {
    if (this.origins.length) {
      this.setOrigin(this.origins[0].code);
    }
  };

  private selectExistOrFirstDestination = () => {
    if (this.destinations.length) {
      const newDestination =
        this.destination && this.destinations.find((destination) => destination.code.toString() === this.destination)
          ? this.destination
          : this.destinations[0].code;
      this.setDestination(newDestination);
    }
  };

  private selectExistOrFirstDuration = () => {
    if (this.durations.length) {
      const newDuration =
        this.duration && this.durations.find((duration) => duration.value.toString() === this.duration)
          ? this.duration
          : this.durations[0].value;
      this.setDuration(newDuration);
    }
  };

  public async loadOrigins(siteid: number): Promise<any> {
    this.siteid = siteid;
    await this.updateOrigins();
  }

  public toggleOffersWithTransfer() {
    this.offersWithTransfer = !this.offersWithTransfer;
  }

  public setOrigin(value: string, selectFirstDestination = true) {
    this.origin = value;
    this.updateDestinations();
    this.updateFormAction();
    if (this.globalState.isFormSubmitted) {
      this.validateOriginField();
    }
    // If siteid requires api call this function will be called once the api request is done
    if (selectFirstDestination && !this.config.siteIdsWithApiCall.includes(this.siteid)) {
      this.selectExistOrFirstDestination();
    }
  }

  public setDestination(value: string) {
    this.destination = value;
    this.updateDurations();
    this.updateFormAction();
    if (this.globalState.isFormSubmitted) {
      this.validateDestinationField();
    }
    this.selectExistOrFirstDuration();
  }

  public setDuration(value: string) {
    this.duration = value;
    if (this.globalState.isFormSubmitted) {
      this.validateDurationField();
    }
  }

  public updateDurations() {
    if (this.config.siteIdsWithApiCall.includes(this.siteid)) {
      this.getDurationsFromApi();
    }
  }

  public updateDestinations() {
    if (this.config.siteIdsWithApiCall.includes(this.siteid)) {
      this.getDestinationsFromApi();
    }
  }

  public async updateOrigins(): Promise<any> {
    if (this.config.siteIdsWithApiCall.includes(this.siteid)) {
      this.origin = "";

      await this.getOriginsFromApi();
    } else {
      this.selectFirstOrigin();
    }

    return true;
  }

  public updateFormAction() {
    const url = this.generateFormAction();
    this.formAction = url;
  }

  public validateMaxDateRange = () => {
    // Check if it is not a single date
    if (this.config.date.queryParam.end) {
      if (!this.globalState.validateMaxDateRange(this.date.start, this.date.end, this.config.date.maxDaysRange)) {
        this.dateEndInvalidKey = this.config.date.invalidMaxDatesRangeMessageToken;
        if (!this.errorInputRef) {
          this.errorInputRef = this.findFocusElement("d2")!;
        }

        return false;
      }
    }

    return true;
  };

  public validateEndDateField = () => this.validateMaxDateRange();

  public resetValidations = () => {
    this.destinationInvalidKey = "";
    this.durationInvalidKey = "";
    this.originInvalidKey = "";
    this.dateStartInvalidKey = "";
    this.dateEndInvalidKey = "";
    this.nonHotelTravelersInvalidKey = "";

    return true;
  };

  findFocusElement = (keyword: string) =>
    this.wizardInputsArray.find((element) => element.current !== null && element.current.outerHTML.includes(keyword));

  public validateOriginField = (): boolean => {
    if (this.origin && this.origin.toString().trim() !== "") {
      this.originInvalidKey = "";

      return true;
    }
    this.originInvalidKey = this.config.origin.invalidMessage;
    this.errorInputRef = this.findFocusElement("origin")!;

    return false;
  };

  public validateDurationField = (): boolean => {
    if (this.duration && this.duration.toString().trim() !== "") {
      this.durationInvalidKey = "";

      return true;
    }

    this.durationInvalidKey = this.config.duration.invalidMessage;
    if (!this.errorInputRef) {
      this.errorInputRef = this.findFocusElement("duration")!;
    }

    return false;
  };

  public validateDestinationField = (): boolean => {
    if (this.destination && this.destination.toString().trim() !== "") {
      this.destinationInvalidKey = "";

      return true;
    }
    this.destinationInvalidKey = this.config.destination.invalidMessage;
    if (!this.errorInputRef) {
      this.errorInputRef = this.findFocusElement("destination")!;
    }

    return false;
  };

  public validateChildrenFields = () => {
    const childrenWithoutAge = this.globalState.validateChildrenFields(this.travelers);

    if (!this.config.travelers.withRooms && Boolean(childrenWithoutAge)) {
      if (childrenWithoutAge === 1) {
        this.nonHotelTravelersInvalidKey = this.config.travelers.invalidChildValueMessageToken!;
      } else {
        this.nonHotelTravelersInvalidKey = this.config.travelers.invalidChildrenValuesMessageToken!;
      }

      return false;
    }

    return true;
  };

  public validateInfantFields = () => {
    const infantWithoutAge = this.globalState.validateInfantFields(this.travelers);

    if (!this.config.travelers.withRooms && Boolean(infantWithoutAge)) {
      if (infantWithoutAge === 1) {
        this.nonHotelTravelersInvalidKey = this.config.travelers.invalidInfantValueMessageToken!;
      } else {
        this.nonHotelTravelersInvalidKey = this.config.travelers.invalidInfantsValuesMessageToken!;
      }

      return false;
    }

    return true;
  };

  public validateTravelersField = () =>
    this.validateLessThanNTravelers() && this.validateChildrenFields() && this.validateInfantFields();

  public validateLessThanNTravelers = () => {
    if (!this.globalState.validateLessThanNTravelers(this.nonHotelTravelersMetadata, this.config.travelers)) {
      this.nonHotelTravelersInvalidKey = this.config.travelers.invalidLessThanNTravelersMessageToken;

      return false;
    }

    return true;
  };

  public validateForm = () => {
    if (this.errorInputRef) {
      this.errorInputRef = null;
    }
    this.numberOfErrors = 0;

    const validations: boolean[] = [
      this.validateOriginField(),
      this.validateDestinationField(),
      this.validateDurationField(),
      this.validateEndDateField(),
      this.validateTravelersField(),
    ];

    validations.forEach((value) => !value && (this.numberOfErrors += 1));

    return !validations.includes(false);
  };

  public overrideConfig(callback: () => void): void {
    callback();
  }

  public updateDateSelection = (start: Date, end: Date) => {
    this.globalState.updateDateSelection(start, end);
    this.dateStartInvalidKey = "";
    this.dateEndInvalidKey = "";
  };

  public updateNonHotelTravelersSelection = (travelers: Travelers) => {
    this.globalState.updateNonHotelTravelersSelection(travelers);
    this.nonHotelTravelersInvalidKey = "";
  };

  moreThanOneError = () => {
    return this.globalState.moreThanOneError(this.numberOfErrors);
  };

  public get travelers() {
    const nonHotelRoom: [Room] = [
      {
        adults: 2,
        children: [],
        infants: [],
      },
    ];

    const applicableTravelerState = this.globalState.travelers.nonHotel;
    // Loop through existing room Info for travelers to find applicable room
    const applicableRoomsForTravelers: Room[] = applicableTravelerState.rooms.map((room: Room) => {
      const applicableAdultsPerRoom =
        room.adults > this.config.travelers.maxAdultsPerRoom ? this.config.travelers.maxAdultsPerRoom : room.adults;
      const applicableChildrenPerRoom =
        room.children.length > this.config.travelers.maxChildrenPerRoom
          ? room.children.splice(0, this.config.travelers.maxChildrenPerRoom)
          : room.children;
      const applicableInfantsPerRoom = this.config.travelers.withInfants
        ? room.infants.length > this.config.travelers.maxInfantsPerRoom
          ? room.infants.splice(0, this.config.travelers.maxInfantsPerRoom)
          : room.infants
        : [];

      return {
        adults: applicableAdultsPerRoom,
        children: applicableChildrenPerRoom,
        infants: applicableInfantsPerRoom,
      };
    });

    // Only the first room details to be preserved in nonHotel [Room]
    nonHotelRoom[0] = applicableRoomsForTravelers[0];

    const thirdTravelers = {
      hotel: {
        rooms: applicableRoomsForTravelers,
        infantSeating: this.globalState.travelers.hotel.infantSeating,
      },
      hotelPackage: {
        rooms: applicableRoomsForTravelers,
        infantSeating: this.globalState.travelers.hotelPackage.infantSeating,
      },
      nonHotel: {
        rooms: nonHotelRoom,
        infantSeating: this.globalState.travelers.nonHotel.infantSeating,
      },
      cruise: {
        rooms: nonHotelRoom,
        infantSeating: this.globalState.travelers.cruise.infantSeating,
      },
    };

    return thirdTravelers;
  }

  public get nonHotelTravelersMetadata() {
    return travelersMetadata(this.travelers.nonHotel);
  }

  public submit = (event: React.FormEvent) => {
    const isValidForm = this.validateForm();
    if (!isValidForm) {
      event.preventDefault();
    }
    this.globalState.submit();
  };

  private findDestination = (destinationCode: string): Location3PPDestination | undefined => {
    return this.destinations.find((destination: Location3PPDestination) => destination.code === destinationCode);
  };

  private generateHeadersApi = () => ({
    Accept: "application/json",
    "Content-Type": "application/json",
  });

  private getDestinationsFromApi() {
    const updateDestinationFetch = () =>
      fetch(`/all-inclusive-vacation-api/v1/origins/${this.origin}/destinations`, {
        method: "GET",
        headers: {
          ...this.generateHeadersApi(),
        },
        credentials: "include",
      });

    return Promise.resolve(updateDestinationFetch())
      .then(safeResponseToJson)
      .then((data) => {
        const subDestinationsFormat = (destinations: Location3PPDestination[]) => {
          return destinations.map((elem) => {
            elem.name = ` - ${elem.name}`;

            return elem;
          });
        };

        const flattenDestinations = (arr: Location3PPDestination[]): any => {
          return arr.reduce((flat, toFlatten: any) => {
            return flat.concat(
              toFlatten.destinations && Array.isArray(toFlatten.destinations) && toFlatten.destinations.length
                ? [].concat(toFlatten).concat(flattenDestinations(subDestinationsFormat(toFlatten.destinations)))
                : toFlatten
            );
          }, []);
        };

        this.destinations = flattenDestinations(data);
        this.selectExistOrFirstDestination();
      })
      .catch((e) => console.log(e));
  }

  private getOriginsFromApi() {
    const updateOriginFetch = () =>
      fetch(`/all-inclusive-vacation-api/v1/origins`, {
        method: "GET",
        headers: {
          ...this.generateHeadersApi(),
        },
        credentials: "include",
      });

    return Promise.resolve(updateOriginFetch())
      .then(safeResponseToJson)
      .then((data) => {
        this.origins = data;
      })
      .catch((e) => console.log(e));
  }

  private getDurationsFromApi() {
    const destination: Location3PPDestination | undefined = this.findDestination(this.destination);
    if (destination && destination.durations) {
      this.durations = destination.durations.map(
        (duration): ThirdPPDuration => {
          const value: string = duration.from === duration.to ? duration.from : `${duration.from}-${duration.to}`;
          const { daysTextApi } = this.config.duration;
          const text: string =
            duration.from === duration.to
              ? `${duration.from} ${daysTextApi}`
              : `${duration.from} - ${duration.to} ${daysTextApi}`;

          return {
            value,
            text,
          };
        }
      );
    } else {
      this.durations = [];
    }
  }

  private generateFormAction() {
    if (this.config.siteIdsWithApiCall.includes(this.siteid)) {
      return `${this.config.form.action}/search`;
    }

    const splittedDestinationValue = this.destination.split(":");
    const url = splittedDestinationValue[1] ? "hotel" : "region";

    return `${this.config.form.action}/${url}`;
  }

  public updateDateFromConfig = () => {
    this.globalState.updateDateFromConfig(this.config.date);
  };
}
