import { observable, makeObservable } from "mobx";
import { ExternalLinkTabConfig } from "stores/wizard/config";

export class ExternalLinkTabWizardState {
  public config: ExternalLinkTabConfig;

  constructor(config: ExternalLinkTabConfig) {
    this.config = config;
    makeObservable(this, {
      config: observable,
    });
  }
}
