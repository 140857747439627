import { GeoType } from "typings/flexFramework/FlexDefinitions";

export abstract class DestinationModifier {
  public updateDestinationOnRegionType(regionType: GeoType, destinationValue: string) {
    const isGreaterThanCity = regionType === "continent" || regionType === "province_state" || regionType === "country";

    if (isGreaterThanCity) {
      this.updateDestination("");
    } else {
      this.updateDestination(destinationValue || "");
    }
  }

  public updateDestination(value: string, regionId = "") {
    this.updateDestinationFromContext(value, regionId, "", "", "", "", "");
  }

  abstract updateDestinationFromContext(
    value: string,
    regionID: string,
    countryCode: string,
    latLong: string,
    ttla: string,
    destinationId: string,
    hotelId: string
  ): void;
}
