import { DateState } from "stores/wizard/state/global/date";

export const dateState = (daysEndOffset = 1, daysStartOffset = 0): DateState => {
  const start = new Date();
  const end = new Date();

  return {
    start: new Date(start.setDate(start.getDate() + daysStartOffset)),
    end: new Date(end.setDate(end.getDate() + daysEndOffset)),
  };
};

/**
 * Utility function that sets the partial Stay Date for hotel.
 * The partial stay dates for Hotel should always lie between the flight start and end dates.
 *
 * If hotel partial start date selected is less than flight start date, or greater than flight end date,
 * this sets partial start date as the flight stard date, otherwise partial start date remains the same.
 *
 * If hotel partial end date selected is greater than flight end date, or less than flight start date,
 * this sets partial end date as the flight end date, otherwise partial end date remains the same.
 *
 * @param: {DateState} date - Flight start and end dates.
 * @param: {DateState} partialStayDate - Hotel partial stay start and end dates.
 * @param: {number} daysOffset - Duration in days between start and end dates.
 */
export function setHotelPartialStayDate(date: DateState, partialStayDate: DateState, daysOffset = 1) {
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + daysOffset);

  partialStayDate.start =
    partialStayDate.start > today
      ? partialStayDate.start < date.start || partialStayDate.start > date.end
        ? date.start
        : partialStayDate.start
      : date.start;

  partialStayDate.end =
    partialStayDate.end > tomorrow
      ? partialStayDate.end > date.end || partialStayDate.end < date.start
        ? date.end
        : partialStayDate.end
      : date.end;
}
