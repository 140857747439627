import { Time } from "./typings";

export const isToday = (dt: Date) => {
  const today = new Date();

  return (
    dt.getDate() === today.getDate() && dt.getMonth() === today.getMonth() && dt.getFullYear() === today.getFullYear()
  );
};

export const isSelectedHourValid = (pickupTime: Time) => {
  const today = new Date();
  const now = today.getHours() * 60 + today.getMinutes();

  return pickupTime.canonicalMinutes >= now;
};

export const isValidTimeDuration = (start: Date, end: Date, pickupTime: Time, dropoffTime: Time) => {
  const pickUpDate = new Date(start);
  const dropOffDate = new Date(end);

  pickUpDate.setMilliseconds(pickupTime.canonicalMinutes * 60 * 1000);

  if (dropOffDate) {
    dropOffDate.setMilliseconds(dropoffTime.canonicalMinutes * 60 * 1000);
    const timeDiffInMillis = dropOffDate.getTime() - pickUpDate.getTime();
    const twoHrsInMillis = 7200000; // 2 * 60 * 60 * 1000;

    return timeDiffInMillis >= twoHrsInMillis;
  }

  return true;
};

export const removeTime = (dateTime: Date) => {
  const date = new Date(dateTime.getTime());
  date.setHours(0, 0, 0, 0);
  return date;
};
