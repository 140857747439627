import { GlobalWizardState, LOBState } from "stores/wizard/state";
import { observable, action, computed, makeObservable } from "mobx";
import { FlightConfig } from "stores/wizard/config";
import { TypeaheadSelection } from "uitk-react-typeahead";
import { Travelers } from "src/components/shared/TravelersField/typings";
import { TypeaheadSelectionProps } from "components/flexComponents/WizardHotelPWA/typings";
import { locationState } from "src/stores/wizard/state/global/location";
import { dateState } from "src/stores/wizard/state/global/date";
import { FlightLegState } from "stores/wizard/state/flight/MultiLegFlights/typings";
import { setHotelPartialStayDate } from "stores/wizard/state/global/date/dateUtils";
import { DateState, TravelersState } from "stores/wizard/state/global";
import { formatDate } from "bernie-l10n";
import { travelersMetadata } from "src/components/shared/TravelersField/utils";
import { Location } from "src/stores/wizard/state/global/location/typings";
import { FlightTypeCodeMap } from "src/stores/wizard/state/flight/typings";
import * as React from "react";
import { ClassCodes } from "src/components/shared/PreferredClassInput/PreferredClassInput";
import { PreferredAirline } from "src/components/shared/PreferredAirlineInput/PreferredAirlineInput";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { FlightType } from "../typings";
import { travelersStateOnConfig } from "../global/travelers/travelersState";
import { DestinationModifier } from "stores/wizard/state/DestinationModifier";
import { ValidationData, ValidationState } from "../validations/typings";
import { WizardValidationState } from "../validations/WizardValidationState";

export class FlightWizardState extends DestinationModifier implements LOBState {
  private globalState: GlobalWizardState;

  public get isDesktop() {
    return this.globalState.isDesktop;
  }

  public get travelersValueChanged() {
    return this.globalState.travelersValueChanged;
  }

  public setTravelersValue = () => {
    this.globalState.setTravelersValue();
  };

  public errorInputRef: React.RefObject<HTMLHeadingElement> | null;
  public errorSummaryRef: React.RefObject<HTMLInputElement> | null;
  public isRoundtripFormValid = true;
  public isOnewayFormValid = true;
  public isMulticityFormValid = true;

  public get flightClassCode() {
    return this.globalState.flightClassCode;
  }

  public get location() {
    return this.globalState.location;
  }

  public config: FlightConfig;
  public validations: ValidationState;
  public subLOBState: FlightType = FlightType.ROUND_TRIP;
  public legsDateStartInvalidKey: string[];
  public listOfAdditionalLegs: FlightLegState[];
  public multiCityFlightLegURL: string[];
  public isAddAHotelChecked = false;
  public isAddACarChecked = false;
  public isNonStopFlightChecked = false;
  public moduleName = "FLT.LP.WIZARD";
  public trackingEnabled = true;
  public numberOfErrors = 0;
  public localDateFormat: string;

  public buildMultiCityFlightLegUrl = (legIndex: number) => {
    const legConfig = this.config.multiCityFlight.legs[legIndex];
    const rawFormatForSubmission = legConfig.date.ISOSubmissionSupport ? legConfig.date.format : this.localDateFormat;

    const { value: origin, metaData: originMetaData } = this.listOfAdditionalLegs[legIndex].location.origin;
    const { value: destination, metaData: destinationMetadata } = this.listOfAdditionalLegs[
      legIndex
    ].location.destination;

    const { ttla: originTtla } = originMetaData;
    const { ttla: destinationTtla } = destinationMetadata;
    const formattedOriginTtla = originTtla ? `(${originTtla})` : "";
    const formattedDestinationTtla = destinationTtla ? `(${destinationTtla})` : "";

    const from = `from:${origin}${formattedOriginTtla},`;
    const to = `to:${destination}${formattedDestinationTtla},`;
    const startDate = formatDate(this.listOfAdditionalLegs[legIndex].date.start, {
      raw: rawFormatForSubmission,
    });
    const departure = `departure:${startDate}TANYT`;

    return from + to + departure;
  };

  public updateLegLocationOrigin(selection: TypeaheadSelection, index: number) {
    const { term, data } = selection as TypeaheadSelectionProps;
    const legToUpdate = this.listOfAdditionalLegs[index];
    legToUpdate.location.origin.value = term;
    legToUpdate.location.origin.metaData.regionId = data && data.regionId;
    legToUpdate.location.origin.metaData.ttla =
      data && data.hierarchyInfo && data.hierarchyInfo.airport && data.hierarchyInfo.airport.airportCode;
    this.multiCityFlightLegURL[index] = this.buildMultiCityFlightLegUrl(index);
  }

  public updateLegLocationDestination(selection: TypeaheadSelection, index: number) {
    const { term, data } = selection as TypeaheadSelectionProps;
    const legToUpdate = this.listOfAdditionalLegs[index];
    legToUpdate.location.destination.value = term;
    legToUpdate.location.destination.metaData.regionId = data && data.regionId;
    legToUpdate.location.destination.metaData.ttla =
      data && data.hierarchyInfo && data.hierarchyInfo.airport && data.hierarchyInfo.airport.airportCode;
    this.multiCityFlightLegURL[index] = this.buildMultiCityFlightLegUrl(index);

    //copy into origin of next leg if that leg exists
    const nextLegExists = index + 1 < this.listOfAdditionalLegs.length;
    if (nextLegExists) {
      this.copyOneLocationIntoAnotherLocation(
        this.listOfAdditionalLegs[index].location.destination,
        this.listOfAdditionalLegs[index + 1].location.origin
      );
    }
  }

  public updateLegDate = (start: Date, end: Date, index: number) => {
    const legToUpdate = this.listOfAdditionalLegs[index];
    legToUpdate.date.start = start;
    legToUpdate.date.end = end;
    this.multiCityFlightLegURL[index] = this.buildMultiCityFlightLegUrl(index);
  };

  public hotelDate: DateState;

  public updateSubLOBState(subLOBState: FlightType) {
    this.subLOBState = subLOBState;
  }

  public get date() {
    return this.globalState.date;
  }

  public flightsTravelers: TravelersState;

  public get travelers() {
    return this.flightsTravelers;
  }

  public get hotelTravelersMetadata() {
    return travelersMetadata(this.travelers.hotel);
  }

  public get nonHotelTravelersMetadata() {
    return travelersMetadata(this.travelers.nonHotel);
  }

  public get lastAdditionalLegDate() {
    return this.listOfAdditionalLegs?.length
      ? this.listOfAdditionalLegs[this.listOfAdditionalLegs.length - 1].date.start
      : null;
  }

  public get cruiseTravelersMetadata() {
    return travelersMetadata(this.travelers.cruise);
  }

  public get flightTripType() {
    return this.subLOBState;
  }

  public get flightSoftPackageTripType() {
    const { roundtrip, oneway } = FlightTypeCodeMap;
    if (this.subLOBState === FlightType.ROUND_TRIP) {
      return roundtrip;
    }
    if (this.subLOBState === FlightType.ONE_WAY) {
      return oneway;
    }

    return "";
  }

  public get flightPackageType() {
    if (this.isAddAHotelChecked && this.isAddACarChecked) {
      return "fhc";
    }
    if (this.isAddAHotelChecked) {
      return "fh";
    }
    if (this.isAddACarChecked) {
      return "fc";
    }
    return "";
  }

  public wizardInputsArray: React.RefObject<HTMLInputElement>[] = [];

  //validations
  public originInvalidKey: string | "";

  moreThanOneError = () => {
    return this.globalState.moreThanOneError(this.numberOfErrors);
  };

  public destinationInvalidKey: string | "";
  public hotelTravelersInvalidKey = "";
  public nonHotelTravelersInvalidKey = "";

  public validateLessThanNTravelers = () => {
    if (
      this.isAddAHotelChecked &&
      !this.globalState.validateLessThanNTravelers(this.hotelTravelersMetadata, this.config.travelersWithHotel)
    ) {
      this.hotelTravelersInvalidKey = this.config.travelersWithHotel.invalidLessThanNTravelersMessageToken;

      return false;
    }
    if (!this.globalState.validateLessThanNTravelers(this.nonHotelTravelersMetadata, this.config.travelers)) {
      this.nonHotelTravelersInvalidKey = this.config.travelers.invalidLessThanNTravelersMessageToken;

      return false;
    }

    return true;
  };

  public validateUnattendedInfantInLap = () => {
    if (
      this.isAddAHotelChecked &&
      !this.globalState.validateUnattendedInfantInLap(
        this.hotelTravelersMetadata,
        this.config.travelersWithHotel,
        this.travelers.hotel.infantSeating
      )
    ) {
      this.hotelTravelersInvalidKey = this.config.travelersWithHotel.invalidUnattendedInfantOnLapMessageToken;

      return false;
    }
    if (
      !this.globalState.validateUnattendedInfantInLap(
        this.nonHotelTravelersMetadata,
        this.config.travelers,
        this.travelers.nonHotel.infantSeating
      )
    ) {
      this.nonHotelTravelersInvalidKey = this.config.travelers.invalidUnattendedInfantOnLapMessageToken;

      return false;
    }

    return true;
  };

  public validateInfantsPerTraveler = () => {
    if (
      this.isAddAHotelChecked &&
      !this.globalState.validateInfantsPerTraveler(
        this.hotelTravelersMetadata,
        this.config.travelersWithHotel,
        this.travelers.hotel.infantSeating
      )
    ) {
      this.hotelTravelersInvalidKey = this.config.travelersWithHotel.invalidNumberOfInfantPerTravelerMessageToken!;

      return false;
    }
    if (
      !this.globalState.validateInfantsPerTraveler(
        this.nonHotelTravelersMetadata,
        this.config.travelers,
        this.travelers.nonHotel.infantSeating
      )
    ) {
      this.nonHotelTravelersInvalidKey = this.config.travelers.invalidNumberOfInfantPerTravelerMessageToken!;

      return false;
    }

    return true;
  };

  public validateChildrenFields = () => {
    const childrenWithoutAge = this.globalState.validateChildrenFields(this.travelers);

    if (this.config.travelers.withRooms && Boolean(childrenWithoutAge)) {
      if (childrenWithoutAge === 1) {
        this.hotelTravelersInvalidKey = this.config.travelers.invalidChildValueMessageToken!;
      } else {
        this.hotelTravelersInvalidKey = this.config.travelers.invalidChildrenValuesMessageToken!;
      }

      return false;
    }

    if (!this.config.travelers.withRooms && Boolean(childrenWithoutAge)) {
      if (childrenWithoutAge === 1) {
        this.nonHotelTravelersInvalidKey = this.config.travelers.invalidChildValueMessageToken!;
      } else {
        this.nonHotelTravelersInvalidKey = this.config.travelers.invalidChildrenValuesMessageToken!;
      }

      return false;
    }

    return true;
  };

  public validateInfantFields = () => {
    const infantWithoutAge = this.globalState.validateInfantFields(this.travelers);

    if (this.config.travelers.withRooms && Boolean(infantWithoutAge)) {
      if (infantWithoutAge === 1) {
        this.hotelTravelersInvalidKey = this.config.travelers.invalidInfantValueMessageToken!;
      } else {
        this.hotelTravelersInvalidKey = this.config.travelers.invalidInfantsValuesMessageToken!;
      }

      return false;
    }

    if (!this.config.travelers.withRooms && Boolean(infantWithoutAge)) {
      if (infantWithoutAge === 1) {
        this.nonHotelTravelersInvalidKey = this.config.travelers.invalidInfantValueMessageToken!;
      } else {
        this.nonHotelTravelersInvalidKey = this.config.travelers.invalidInfantsValuesMessageToken!;
      }

      return false;
    }

    return true;
  };

  public validateTravelersField = () =>
    this.validateLessThanNTravelers() &&
    this.validateUnattendedInfantInLap() &&
    this.validateInfantsPerTraveler() &&
    this.validateChildrenFields() &&
    this.validateInfantFields();

  public dateStartInvalidKey = "";
  public dateEndInvalidKey = "";
  public hotelStartDateInvalidKey = "";
  public hotelEndDateInvalidKey = "";

  public resetValidations = () => {
    this.originInvalidKey = "";
    this.destinationInvalidKey = "";
    this.dateStartInvalidKey = "";
    this.dateEndInvalidKey = "";
    this.hotelStartDateInvalidKey = "";
    this.hotelEndDateInvalidKey = "";
    this.hotelTravelersInvalidKey = "";
    this.nonHotelTravelersInvalidKey = "";
    const maxNumberOfAdditionalLegs = this.config.multiCityFlight.maxNumberOfAdditionalLegs;
    this.legsDateStartInvalidKey = new Array<string>(maxNumberOfAdditionalLegs).fill("", 0, maxNumberOfAdditionalLegs);
  };

  public overrideConfig(callback: () => void): void {
    callback();
  }

  public updateDateSelection = (start: Date, end: Date, multiLegIndex?: number) => {
    if (multiLegIndex !== undefined) {
      this.updateLegDate(start, end, multiLegIndex);
    } else {
      this.globalState.updateDateSelection(start, end);
      this.dateStartInvalidKey = "";
      this.dateEndInvalidKey = "";
      this.hotelStartDateInvalidKey = "";
      this.hotelEndDateInvalidKey = "";
    }
  };

  public updateOriginSelection = (selection: TypeaheadSelection, index?: number) => {
    //in the case of a multi-city flight, update the individual leg
    if (index !== undefined) {
      this.updateLegLocationOrigin(selection, index);
    } else {
      this.globalState.updateOriginSelection(selection);
      this.originInvalidKey = "";
    }
  };

  public updateHotelDateSelection = (start: Date, end: Date) => {
    this.hotelDate.start = start;
    this.hotelDate.end = end;
    this.hotelStartDateInvalidKey = "";
    this.hotelEndDateInvalidKey = "";
  };

  public updateDestinationSelection = (selection: TypeaheadSelection, index?: number) => {
    if (index !== undefined) {
      this.updateLegLocationDestination(selection, index);
    } else {
      this.globalState.updateDestinationSelection(selection);
      this.destinationInvalidKey = "";

      //copy main leg into first additional leg
      this.copyOneLocationIntoAnotherLocation(this.location.destination, this.listOfAdditionalLegs[0].location.origin);
    }
  };

  public swapLocations() {
    this.globalState.swapOriginAndDestinationLocations();

    //update for reflection
    this.copyOneLocationIntoAnotherLocation(this.location.destination, this.listOfAdditionalLegs[0].location.origin);
  }

  public setFlightClass(flightClassCode: ClassCodes) {
    this.globalState.setFlightClass(flightClassCode);
  }

  public setFlightAirline(flightAirline: PreferredAirline) {
    this.globalState.setFlightAirline(flightAirline);
  }

  public addALeg = () => {
    if (this.listOfAdditionalLegs.length < this.config.multiCityFlight.maxNumberOfAdditionalLegs) {
      this.listOfAdditionalLegs.push({
        location: locationState(),
        date: this.globalState.getDateFromConfig(this.config.date),
      });
      const index = this.listOfAdditionalLegs.length - 1;
      const notFirstLeg = index > 0;
      setTimeout(() => {
        const focusIndex = this.wizardInputsArray.length - 2;
        this.wizardInputsArray[focusIndex]?.current?.focus();
      });
      if (notFirstLeg) {
        this.copyOneLocationIntoAnotherLocation(
          this.listOfAdditionalLegs[index - 1].location.destination,
          this.listOfAdditionalLegs[index].location.origin
        );
      }
    }
  };

  //copies a non-empty location into another location. Will overwrite contents.
  public copyOneLocationIntoAnotherLocation = (locationToCopyFrom: Location, locationToCopyInto: Location) => {
    if (locationToCopyFrom.value && locationToCopyFrom.value !== "") {
      locationToCopyInto.value = locationToCopyFrom.value;
      locationToCopyInto.metaData.countryCode = locationToCopyFrom.metaData.countryCode;
      locationToCopyInto.metaData.latLong = locationToCopyFrom.metaData.latLong;
      locationToCopyInto.metaData.regionId = locationToCopyFrom.metaData.regionId;
      locationToCopyInto.metaData.shortName = locationToCopyFrom.metaData.shortName;
      locationToCopyInto.metaData.ttla = locationToCopyFrom.metaData.ttla;
    }
  };

  public removeALeg = (index: number) => {
    if (index < this.listOfAdditionalLegs.length) {
      this.listOfAdditionalLegs.splice(index, 1);
    }
  };

  public updateDestinationFromContext(
    value: string,
    regionID: string,
    countryCode: string,
    latLong: string,
    ttla: string,
    destinationId: string,
    hotelId: string
  ) {
    this.globalState.updateDestinationFromContext(value, regionID, countryCode, latLong, ttla, destinationId, hotelId);
    this.destinationInvalidKey = "";
  }

  public updateOriginFromContext(value: string, regionID: string, countryCode: string, latLong: string, ttla: string) {
    this.globalState.updateOriginFromContext(value, regionID, countryCode, latLong, ttla);
    this.originInvalidKey = "";
  }

  public updateStateFromContext = (context: ExtendedContextStore) => {
    const originFromContext = context.searchContext.origin;
    const originParent = originFromContext ? originFromContext.parent : undefined;
    const destinationFromContext = context.searchContext.destination;
    const destinationParent = destinationFromContext ? destinationFromContext.parent : undefined;

    if (originFromContext) {
      this.updateOriginFromContext(
        originFromContext.extendedName || "",
        originFromContext.id || "",
        originParent?.code || "",
        originParent?.geoCoordinate?.latitude && originParent?.geoCoordinate?.longitude
          ? `${originParent.geoCoordinate.latitude},${originParent.geoCoordinate.longitude}`
          : "",
        originFromContext.code || ""
      );
    } else {
      this.updateOriginFromContext("", "", "", "", "");
    }

    if (destinationFromContext) {
      this.updateDestinationFromContext(
        destinationFromContext.extendedName || "",
        destinationFromContext.id || "",
        destinationParent?.code || "",
        destinationParent?.geoCoordinate?.latitude && destinationParent.geoCoordinate?.longitude
          ? `${destinationParent.geoCoordinate.latitude},${destinationParent.geoCoordinate.longitude}`
          : "",
        destinationFromContext.code || "",
        destinationFromContext.id || "",
        ""
      );
    } else {
      this.updateDestinationFromContext("", "", "", "", "", "", "");
    }
  };

  public updateStateValidations = (validationsData: any) => {
    this.updateLocErrorKeys(validationsData.invalidKeys);
    this.errorInputRef = validationsData.inputReference;
    this.numberOfErrors = validationsData.numberOfErrors;
  };

  public updateLocErrorKeys = (invalidKeys: any) => {
    this.dateEndInvalidKey = invalidKeys.dateEnd;
    this.destinationInvalidKey = invalidKeys.destination;
    this.hotelTravelersInvalidKey = invalidKeys.travelers;
    this.originInvalidKey = invalidKeys.origin;
    this.hotelStartDateInvalidKey = invalidKeys.addHotelCheckboxStartDate;
    this.hotelEndDateInvalidKey = invalidKeys.addHotelCheckboxEndDate;
  };

  public submit = (event: React.FormEvent) => {
    const { origin, destination } = this.location;
    const { start, end } = this.date;
    const addHotelCheckboxStartDate = this.hotelDate.start;
    const addHotelCheckboxEndDate = this.hotelDate.end;
    const endDate = this.subLOBState === FlightType.ROUND_TRIP ? end : null;

    const addHotelCheckbox = this.isAddAHotelChecked
      ? {
          addHotelCheckbox: {
            dates: {
              start: addHotelCheckboxStartDate,
              end: addHotelCheckboxEndDate,
            },
          },
        }
      : null;

    const dataToValidate: ValidationData = {
      location: {
        origin: origin.value,
        destination: destination.value,
      },
      dates: {
        start,
        end: endDate,
      },
      ...addHotelCheckbox,
      references: this.wizardInputsArray,
    };

    const { isValid, data } = this.validations.validateForm(dataToValidate, this.config);

    if (!isValid) {
      event.preventDefault();
      this.updateInvalidFormsState(false);
      this.updateStateValidations(data);
    }
    this.globalState.submit();
  };

  /**
   * Cleans error state in all forms
   */
  public cleanErrorState = () => {
    if (this.errorInputRef) {
      this.errorInputRef = null;
    }
    this.numberOfErrors = 0;
    this.isOnewayFormValid = true;
    this.isMulticityFormValid = true;
    this.isRoundtripFormValid = true;
  };

  /**
   * Resets other flight forms to not have errors when you change to a different flight type
   * Shows error message in the right flight type
   */
  public updateInvalidFormsState = (validState?: boolean) => {
    const state = validState !== undefined ? validState : true;
    switch (this.flightTripType) {
      case FlightType.ROUND_TRIP:
        this.isRoundtripFormValid = state;
        this.isOnewayFormValid = true;
        this.isMulticityFormValid = true;
        break;
      case FlightType.ONE_WAY:
        this.isOnewayFormValid = state;
        this.isRoundtripFormValid = true;
        this.isMulticityFormValid = true;
        break;
      default:
        this.isMulticityFormValid = state;
        this.isRoundtripFormValid = true;
        this.isOnewayFormValid = true;
        break;
    }
  };

  public updateHotelTravelersSelection = (travelers: Travelers) => {
    this.globalState.updateHotelTravelersSelection(travelers, this.travelers);

    this.hotelTravelersInvalidKey = "";
    this.nonHotelTravelersInvalidKey = "";
  };

  public updateNonHotelTravelersSelection = (travelers: Travelers) => {
    this.globalState.updateNonHotelTravelersSelection(travelers, this.travelers);

    this.hotelTravelersInvalidKey = "";
    this.nonHotelTravelersInvalidKey = "";
  };

  public toggleAddAHotelCheckbox = () => {
    this.isAddAHotelChecked = !this.isAddAHotelChecked;
    setHotelPartialStayDate(this.date, this.hotelDate);
    this.hotelTravelersInvalidKey = "";
    this.nonHotelTravelersInvalidKey = "";
  };

  public toggleAddCarCheckbox = () => {
    this.isAddACarChecked = !this.isAddACarChecked;
  };

  public toggleNonStopFlightCheckbox = () => {
    this.isNonStopFlightChecked = !this.isNonStopFlightChecked;
  };

  /**
   * dynamically build additional flight leg list from min number in config
   * Add one less than the min number to the array, as the first leg is in this.location
   */
  private populateFlightLegs = () => {
    this.listOfAdditionalLegs = [];
    for (let i = 0; i < this.config.multiCityFlight.minNumberOfLegs - 1; i++) {
      const newLeg: FlightLegState = {
        location: locationState(),
        date: this.globalState.getDateFromConfig(this.config.date),
      };
      this.listOfAdditionalLegs.push(newLeg);
    }
  };

  /**
   * * dynamically build flight leg list from config again ... after the config get update
   */
  public updateFlightLegsStateDatesByConfig() {
    this.listOfAdditionalLegs.forEach((flightLegState) => {
      flightLegState.date = this.globalState.getDateFromConfig(this.config.date);
    });
  }

  public updateDateFromConfig = () => {
    this.globalState.updateDateFromConfig(this.config.date);
  };

  constructor(config: FlightConfig, globalState: GlobalWizardState) {
    super();
    makeObservable(this, {
      errorInputRef: observable,
      errorSummaryRef: observable,
      isRoundtripFormValid: observable,
      isOnewayFormValid: observable,
      isMulticityFormValid: observable,
      config: observable,
      subLOBState: observable,
      legsDateStartInvalidKey: observable,
      listOfAdditionalLegs: observable,
      multiCityFlightLegURL: observable,
      isAddAHotelChecked: observable,
      isAddACarChecked: observable,
      isNonStopFlightChecked: observable,
      moduleName: observable,
      trackingEnabled: observable,
      numberOfErrors: observable,
      hotelDate: observable,
      flightsTravelers: observable,
      originInvalidKey: observable,
      destinationInvalidKey: observable,
      hotelTravelersInvalidKey: observable,
      nonHotelTravelersInvalidKey: observable,
      dateStartInvalidKey: observable,
      dateEndInvalidKey: observable,
      hotelStartDateInvalidKey: observable,
      hotelEndDateInvalidKey: observable,
      setTravelersValue: action,
      buildMultiCityFlightLegUrl: action,
      updateLegLocationOrigin: action,
      updateLegLocationDestination: action,
      updateLegDate: action,
      updateSubLOBState: action,
      moreThanOneError: action,
      validateLessThanNTravelers: action,
      validateUnattendedInfantInLap: action,
      validateInfantsPerTraveler: action,
      validateChildrenFields: action,
      validateInfantFields: action,
      validateTravelersField: action,
      resetValidations: action,
      overrideConfig: action,
      updateDateSelection: action,
      updateOriginSelection: action,
      updateDestinationSelection: action,
      swapLocations: action,
      setFlightClass: action,
      setFlightAirline: action,
      addALeg: action,
      removeALeg: action,
      updateDestinationFromContext: action,
      updateOriginFromContext: action,
      submit: action,
      cleanErrorState: action,
      updateInvalidFormsState: action,
      updateHotelTravelersSelection: action,
      updateNonHotelTravelersSelection: action,
      toggleAddAHotelCheckbox: action,
      toggleAddCarCheckbox: action,
      toggleNonStopFlightCheckbox: action,
      updateDestinationOnRegionType: action,
      updateDestination: action,
      travelersValueChanged: computed,
      flightClassCode: computed,
      location: computed,
      date: computed,
      travelers: computed,
      hotelTravelersMetadata: computed,
      nonHotelTravelersMetadata: computed,
      lastAdditionalLegDate: computed,
      cruiseTravelersMetadata: computed,
      flightTripType: computed,
      flightSoftPackageTripType: computed,
      flightPackageType: computed,
    });
    this.validations = new WizardValidationState();
    this.config = config;
    this.globalState = globalState;
    this.flightsTravelers = travelersStateOnConfig(this.config.travelers);
    this.populateFlightLegs();
    const maxNumberOfAdditionalLegs = this.config.multiCityFlight.maxNumberOfAdditionalLegs;
    this.legsDateStartInvalidKey = new Array<string>(maxNumberOfAdditionalLegs).fill("", 0, maxNumberOfAdditionalLegs);
    this.multiCityFlightLegURL = new Array<string>(maxNumberOfAdditionalLegs);
    this.hotelDate = dateState();
  }
}
