import { LocationState } from "stores/wizard/state/global/location";
import { HotelLocationState } from "stores/wizard/state/global/location/typings";

export const locationState = (): LocationState => {
  return {
    origin: {
      value: "",
      metaData: {
        regionId: "",
        ttla: "",
        latLong: "",
        countryCode: "",
      },
    },
    destination: {
      value: "",
      metaData: {
        regionId: "",
        ttla: "",
        latLong: "",
        countryCode: "",
      },
    },
  };
};

export const hotelLocationState = (): HotelLocationState => {
  return {
    destination: {
      value: "",
      metaData: {
        regionId: "",
        ttla: "",
        latLong: "",
        countryCode: "",
      },
    },
  };
};
